/**
 * CompanySummary wraps a company summary resource
 * and provides convenience methods to access
 * its properties and frequently required state.
 */

import type { ApiCompanySummary } from '@main/api/resources/companies';
import { Logo } from '@main/domain/utilities/Logo';
import { countryNameFromAlpha3 } from '@main/utilities/geo';
import { googleLink, localUrl, normalizeUrl, urlDescription } from '@main/utilities/urls';

export class CompanySummary<T extends ApiCompanySummary = ApiCompanySummary> {
    constructor( protected readonly resource: T | null ) {}

    apiResource(): T {
        if ( !this.resource ) {
            throw new Error( 'Make sure to not request the resource if it is not set yet.' );
        }

        return this.resource;
    }

    // region core

    uuid() {
        return this.resource?.uuid || '';
    }

    hasTradeData() {
        return !!this.resource?.hasTradeData;
    }

    name() {
        return this.resource?.name || '';
    }

    legalName() {
        return this.resource?.legalName || '';
    }

    hasEmployees() {
        return !!this.resource?.numberOfEmployees;
    }

    numberOfEmployees() {
        return this.resource?.numberOfEmployees || null;
    }

    revenue(): number | null {
        const revenue = this.resource?.revenue;
        if ( revenue !== undefined && revenue !== null ) {
            return revenue;
        }
        return null;
    }

    hasRevenue(): boolean {
        return this.revenue() !== null;
    }

    normalizedRevenue( revenue: number ): string | number {
        if ( revenue >= 10 ** 9 ) {
            return ( revenue / 10 ** 9 ).toFixed( 2 ) + ' Bil';
        } else if ( revenue >= 10 ** 6 ) {
            return ( revenue / 10 ** 6 ).toFixed( 2 ) + ' Mil';
        } else {
            return revenue;
        }
    }

    getNormalizedRevenue(): string | number {
        const revenue = this.revenue();
        if ( revenue !== null ) {
            return this.normalizedRevenue( revenue );
        }
        return '?';
    }

    hasUrl() {
        return !!this.url();
    }

    url() {
        if ( !this.resource?.url ) {
            return '';
        }

        return normalizeUrl( this.resource.url );
    }

    urlDescription() {
        return urlDescription( this.url() );
    }

    urlText() {
        return this.urlDescription().text;
    }

    // endregion

    // region address

    address() {
        return this.resource?.address || null;
    }

    countryCode() {
        return this.address()?.country || '';
    }

    cityAndCountry() {
        if ( this.countryName() && this.locality() ) {
            return this.locality() + ', ' + this.countryName();
        }

        if ( this.countryName() ) {
            return this.countryName();
        }

        return '';
    }

    hasCityAndCountry() {
        return this.cityAndCountry() !== '';
    }

    countryAndCity() {
        if ( this.countryName() && this.locality() ) {
            return this.countryName() + ', ' + this.locality();
        }

        if ( this.countryName() ) {
            return this.countryName();
        }

        return '';
    }

    countryName() {
        const code = this.countryCode();

        if ( code === '' ) {
            return '';
        }

        return countryNameFromAlpha3( code );
    }

    locality() {
        // Corresponds to the city in most cases.
        return this.address()?.locality || '';
    }

    location() {
        return this.resource?.location || null;
    }

    streetAddress() {
        return this.address()?.streetAddress || '';
    }

    // endregion

    // region Keywords

    topOfferKeywords() {
        return this.resource?.topOfferKeywords || [];
    }

    hasTopOfferKeywords() {
        return this.topOfferKeywords().length > 0;
    }

    // endregion

    // region logo

    logo() {
        return new Logo( this.resource?.name );
    }

    logoUrl() {
        return this.resource?.logoUrl || '';
    }

    // endregion

    // region website preview
    websitePreviewUrl() {
        return this.resource?.websitePreviewUrl ?? undefined;
    }

    // endregion

    // region profile

    profileUrl() {
        if ( !this.resource?.profileUrl ) {
            return '';
        }

        return localUrl( this.resource.profileUrl );
    }

    // endregion

    // region external links

    googleLink() {
        return googleLink( this.legalName(), this.countryName(), this.locality() );
    }

    // endregion

    // region debugging

    public debugToolsUrl() {
        // Dev Tools refers to the tools that enable matchory employees to browse and check the
        // Elasticsearch index. These tools are currently implemented in the backend, but will need
        // to find a better home.
        if ( import.meta.env.VITE_DEV_TOOLS_AVAILABLE !== 'true' ) {
            return null;
        }

        return localUrl( `/.nova/index-analysis/companies/?uuid=${this.uuid()}` );
    }

    // endregion
}
